import StuDashBoardCss from './stuDashboard.module.css';
import LeftMenu from './LeftMenu';
import Header from './header';
import {apiBaseURL,apiURL,apiKey,RouteURL} from './../../constant';
import { useEffect, useState, useContext } from 'react';
import {Row, Col} from "react-bootstrap";
import Loader from "./../../Loader";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { MergeTypeSharp, Subject } from '@mui/icons-material';
import userContext from './../../Store';
import PracticeSetIcon from '../../assets/set_sub.png';
import Swal from 'sweetalert2';
import Alert from '@mui/material/Alert';
import finalExam from '../../assets/final_exam.png';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from "@mui/icons-material/Cancel";

function Mock_CUET() {

  useEffect(()=>{
    getSectionAssignOfStudent();
	// getSubjectOfSec();
  },[])

// state start frome here
const[showLoader, setLoader]=useState(false);
const[showSection, setSection]=useState([]);
const [checkBtn, setCheckedBtn] = useState('');
const[showConfirmPop, setConfirmPop]=useState(false);
const[showSubjects, setSubjects]=useState(false);
const[showSubjectOfS, setSubjectOfS]=useState([]);
const[langData , setlanguage] = useState(false)
const {userData} = useContext(userContext);
const[showButton, setShowBtn] = useState(false)
const[allData, setAllData] = useState({secID:'', subID:'',assessmentID:'',cusetID:''})
const[showAllSet, setAllSetData] = useState(false)
const[getSetData, setAllSet] = useState([])
const[showErrPopup, setErrPopup] =useState({status:false, msg:null});
const[schoolBankTypeData, setSchoolBankType] = useState()
// const[allData, setAllData] = useState({secID:'', subID:''})

// state end frome here
const [showInstructionOn, setInstructionOn] = useState(false);
	function closeCartBox(){	
		setInstructionOn(false);	
	}
function checkBtnData(sectionID){
	setAllSet([])
	setAllData((prev)=>{
		return {...prev, secID:sectionID}
	})
    setCheckedBtn(sectionID)
	if(sectionID==1){
		// setConfirmPop(true)
		setSubjects(false)
		setlanguage(true)
	}else if(sectionID==2){
		setSubjects(true)
		setConfirmPop(false)
		setlanguage(false)
	}else{
		setSubjects(false)
		setConfirmPop(false)
		setlanguage(false)
		setAllData((prev)=>{
			return {...prev,secID:sectionID, subID:''}
		})
	}
	if(sectionID!=3){
		getSubjectByID(sectionID);
	}else{
		getCucetSets(sectionID)
	}
	
}

function mockTesHandler(type){
	setAllData((prev)=>{
		return {...prev, cusetID:type}
	})
}
function getSubjectByID(sectionID){
	setShowBtn(false)
	const xPostData ={
		"section":sectionID,
		"schoolCode" : userData.schoolCode,
		"userRefID" : userData.userRefID
	}
	fetch(apiBaseURL+apiURL.getSubjectsOfSection,{
		method: "POST",
		headers: {'Key' : apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json())
	.then((subjectDataOfec)=>{
		if(subjectDataOfec.status=="success"){
			setSubjectOfS(subjectDataOfec.data.subjects)
		}else{
			alert(subjectDataOfec.message)
		}
	})
	.catch((catch_err)=>{
		setSection([])
		alert(catch_err)
	})
}
//   get Section Function start
function getSectionAssignOfStudent(){
	const xPostData ={
		"classID":12,
		"schoolCode":userData.schoolCode,
		"studentID":userData.userRefID
	}
	setLoader(true)
	fetch(apiBaseURL+apiURL.getAssignedSection,{
		method : 'POST',
		headers: {'Key':apiKey},
		body:JSON.stringify(xPostData)
	})
	.then(responce => responce.json() )
	.then((sectionData)=>{
		if(sectionData.status=="success"){
			setSection(sectionData.data.assignedSections)
		}else{
			setSection([])
			alert(sectionData.message)
		}
	})
	.catch((catch_err)=>{
		setSection([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
}

function showBtn(subjectID){
	setAllSet([])
	setAllData((prev)=>{
		return {...prev,subID:subjectID}
	})
	setShowBtn(true)
}

//   get Section Function end
function getCucetSets(sectionID){
	if(allData.cusetID=='' && userData.schoolCode!="SWASCH1"){
		setErrPopup((prev)=>{
			return {...prev, status:true, msg:"Please select School Bank Type"}
		})
		return
	}
	setLoader(true)
	const xPostData ={
		// "classID":12,
		"schoolCode": userData.schoolCode,
		// "userRefID":userData.userRefID,
		"sectionID":sectionID ? sectionID : allData.secID,
		"subjectID": sectionID ? "" : allData.subID,
		"questionBankTypeID": (userData.schoolCode=="SWASCH1") ? 1 : schoolBankTypeData
	}
	if(userData.schoolCode=="SWASCH1" ){
		fetch(apiBaseURL+apiURL.mockCuetExamList,{
			method : 'POST',
			headers: {'Key':apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json() )
		.then((mockCucet)=>{
			if(mockCucet.status=="success"){
				if(mockCucet.data == ""){
					setErrPopup((prev)=>{
						return {...prev, status:true, msg:'No Record Found !'}
					})
				}else{
					setAllSet(mockCucet.data)
					setAllSetData(true)
				}
				

			}else{
				setAllSet([])
				// alert(mockCucet.message)
				setErrPopup((prev)=>{
					return {...prev, status:true, msg:'No Record Found !'}
				})
			}
		})
		.catch((catch_err)=>{
			setAllSet([])
			alert(catch_err)
		})
		.finally(()=>{
			setLoader(false)
		})
	}else{
		fetch(apiBaseURL+apiURL.selfSchoolMockCuetExamList,{
			method : 'POST',
			headers: {'Key':apiKey},
			body:JSON.stringify(xPostData)
		})
		.then(responce => responce.json() )
	.then((mockCucet)=>{
		if(mockCucet.status=="success"){
			if(mockCucet.data == ""){
				setErrPopup((prev)=>{
					return {...prev, status:true, msg:'No Record Found !'}
				})
				setAllSet([])
			}else{
				setAllSet(mockCucet.data)
				setAllSetData(true)
			}
			

		}else{
			setAllSet([])
			// alert(mockCucet.message)
			setErrPopup((prev)=>{
				return {...prev, status:true, msg:'No Record Found !'}
			})
		}
	})
	.catch((catch_err)=>{
		setAllSet([])
		alert(catch_err)
	})
	.finally(()=>{
		setLoader(false)
	})
	}
	
}
// console.log(getSetData)
function getAssessmentID(setID){
	const xPostData ={
		"classID":12,
		"setID" : setID,
		"schoolCode": userData.schoolCode,
		"userRefID":userData.userRefID,
		"sectionID":allData.secID,
		"subjectID": allData.subID,
		"schoolBankType": (userData.schoolCode=="SWASCH1") ? 1 : schoolBankTypeData
	}

	let assessmentID = 0;
	let assessmentName = ''
	let schoolBankType= ''
	let assAssign = false;
	Swal.fire({
		// title: 'Are you sure ?',
		html: 'Before proceeding further make sure you have read all general instructions carefully. <br/><br/>Do you really want to proceed ? <br>',
		// icon: 'question',
		confirmButtonText: 'Yes',
		confirmButtonColor: '#198754',
		focusCancel: true,
		showCancelButton: true,
		showLoaderOnConfirm: true,
		preConfirm: () => {
			
			const conf = {
				method:'post',
				headers:{'key':apiKey},
				body:JSON.stringify(xPostData )
			};
			
			setLoader(true);
			return fetch( apiBaseURL+apiURL.generateMockCuetSetExam, conf )
					.then( response => response.json() )
					.then( ( result ) => {
						if( result.status=='success' ) {
							assAssign=true
							assessmentID = result.data.assessmentID;
							assessmentName = result.data.assessmentName
							schoolBankType = result.data.schoolBankType
							setLoader(false);
						}
						return result.message;
					} )
					.catch( (error) => {
						Swal.showValidationMessage("failed");
					} )
		},
		allowOutsideClick: () => !Swal.isLoading()
	}).then( (r) => {
		// console.log(assessmentID);
		if( r.isConfirmed ){
			
			if(assessmentID!=0 && assessmentName!='' && assAssign==true){
				// Swal.fire(
				// 	'Success', 'Successfully Done.', 'success'
				// );
				const attemptURL = {
					pathname: RouteURL.studentAttemptScreenPage+"/"+assessmentID+"/"+assessmentName+"/"+schoolBankType
				}
				window.open(attemptURL.pathname, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=2000px,height=1124")
			}else if(assAssign==false){
				Swal.fire(
					'failed', 'Assessment Not Assigned for this Students', 'warning'
				);
			}else{
				setLoader(false);
				Swal.fire(
					'failed', 'Sufficient  Questions are not available.', 'warning'
				);
			}
		}

	} );
}


// get Chapter functions start

// get Chapter functions end




  return (
   <div className={`${StuDashBoardCss.Backbody}`}>
	   	{showInstructionOn && (
        <>
			<div className={`${StuDashBoardCss.addMoreSubjectBg_instruction}`}>
				<div className={`${StuDashBoardCss.generalInstructionBg_instruction}`}>
					<div className={`${StuDashBoardCss.headingBk}`}>
						<Row>
							<Col>
								<div className={`${StuDashBoardCss.SectionHeading}`}>General Instructions</div>
							</Col>
							<Col>
								<CancelIcon	onClick={() => {closeCartBox()}} className={`${StuDashBoardCss.crossbutton}`}/>
							</Col>
						</Row>
					</div>
					<div className={`${StuDashBoardCss.subjectBody_instruction}`}>
						<h5>Self Generated Practice Exams </h5>
						<h6>General Instructions:</h6>
						<ol>
							<li>There will be 50 Questions for each test papers.</li>
							<li>All Questions are compulsory.</li>
							<li>Duration for each test paper would be 60 minutes.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
						</ol>
						{/* <h6><strong>Language</strong></h6>
						<ol>
							<li>From three passages, only 2 passages are to be attempted. (If you attempt all three passages , then first two passages will be considered.)</li>
							<li>From literary aptitude, all 10 questions are to be attempted.</li>
							<li>From vocabulary section all 10 questions are to be attempted.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol>
						<h6><strong>Domain Specific Subjects</strong></h6>
						<p><strong>Maths</strong></p>
						<ol>
							<li>There will be one Question Paper which will contain Two Sections i.e. <strong>Section A and Section B1</strong>.</li>
							<li>Section A will have 15 questions covering Mathematics which will be compulsory for all candidates.</li>
							<li>Section B1 will have 35 questions from Mathematics out of which 25 questions need to be attempted.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>If you attempt more than 40 questions, then first 40 questions will be considered.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol>
						<p><strong>Computer Science</strong></p>
						<ol>
							<li>There will be one Question Paper which will contain Two Sections i.e. Section A and Section B1.</li>
							<li>Section A will have 15 questions covering Computer Science which will be compulsory for all candidates.</li>
							<li>Section B1 will have 35 questions from Computer Science out of which 25 questions need to be attempted.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
						</ol>
						<p><strong>Physics/Chemistry/Biology/Business Studies/Accountancy/Economics/History/Geography/Pol Science</strong></p>
						<ol>
							<li>There are 50 questions in this paper.</li>
							<li>40 questions are to be attempted only.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>If you attempt more than 40 questions then first 40 questions will be considered.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol>
						<h6><strong>General Aptitude Test</strong></h6>						
						<ol>
							<li>There are 60 questions in this paper.</li>
							<li>50 questions are to be attempted only.</li>
							<li>Each question carries 5 marks. There is a negative marking of 1 mark for every wrong answer.</li>
							<li>If you attempt more than 50 questions then first 50 questions will be considered.</li>
							<li>You can review answers of attempted questions only.</li>
						</ol> */}
					</div>
				</div>
			</div>
        </>
        )}
      <Header/>
      <LeftMenu/>
      <div className={`${StuDashBoardCss.backdiv}`}>
			<div className={`${StuDashBoardCss.RowCOlm}`}>
				<div className={`${StuDashBoardCss.headingPractice}`}>Mock CUET
					<div className={`${StuDashBoardCss.headingHelpIcon}`} onClick={() => {setInstructionOn(true);}}>General Instructions <i class="fa"> <InfoIcon /> </i></div>
				</div>
			</div>
		{userData.schoolCode!="SWASCH1" &&
		<>
			<label>Select Question Bank</label>
			<select className='form-control' onChange={(e)=>{setSchoolBankType(e.target.value); mockTesHandler(e.target.value)}}>
				<option hidden>Select</option>
				<option value="1">Forever Question Bank</option>
				<option value="2">School/Institution Question Bank</option>
			</select>
		</>
		}
			{/* <label>Select Question Bank</label>
			<select className='form-control' onChange={(e)=>{setSchoolBankType(e.target.value); mockTesHandler(e.target.value)}}>
				<option hidden>Select</option>
				<option value="1">Forever Question Bank</option>
				{userData.schoolCode=="SWASCH1" ? "" : <option value="2">School/Institution Question Bank</option>}
			</select> */}
			{/* sections code start */}
				<div className={`${StuDashBoardCss.sectionRow}`} style={{textAlign: "left"}}>
					{showSection.map((item)=>{
						return(
							<FormControlLabel  className={`${StuDashBoardCss.sectionBtndiv}`} checked={checkBtn==item.sectionID} name='section' value="sction" control={<Radio />} label={item.sectionName} onClick={()=>{checkBtnData(item.sectionID);}}/>
						)
					})}
				</div>
     		 {/* sections code end */}
			  <hr/>

			  {/* show select combobox start */}
			  <Row>
					{langData &&
						<form method='get' className={`${StuDashBoardCss.TopicWisePractice}`}>
							<Row className='justify-content-start'>
								<Col className='col-md-10 col-10 col-sm-10'>
									<label>Language</label>
									<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(e)=>{showBtn(e.target.value)}}>
										<option className={`d-none`}>Select </option>
											{showSubjectOfS.map((item)=>{
												return(
													<option value={item.subjectID}>{item.subjectName}</option>
												)
											})}
									</select>
								</Col>
								{showButton &&
									<Col className='col-md-2 col-2 col-sm-2 mt-2'>
										{/* <button type='button' onClick={()=>{setConfirmPop(true); generateMockAss()}} className='btn btn-primary mt-4'>Generate</button> */}
										<button type='button' onClick={()=>{ getCucetSets()}} className='btn btn-primary mt-4'>Search</button>
									</Col>
								}
							</Row>
					 	</form>
					}
					
					{showSubjects &&
						 <form method='get' className={`${StuDashBoardCss.TopicWisePractice}`}>
							<Row className='justify-content-start'>
								<Col className='col-md-10 col-10 col-sm-10'>
									<label>Subject</label>
									<select required className={`${StuDashBoardCss.selectSubject}`} onChange={(e)=>{showBtn(e.target.value)}}>
										<option className={`d-none`}>Select </option>
											{showSubjectOfS.map((item)=>{
												return(
													<option value={item.subjectID}>{item.subjectName}</option>
												)
											})}
									</select>
								</Col>
								{showButton &&
									<Col className='col-md-2 col-2 col-sm-2 mt-2'>
										<button type='button' onClick={()=>{ getCucetSets()}} className='btn btn-primary mt-4'>Search</button>
									</Col>
								}
							</Row>
					 	</form>
					}

					

			  </Row>
			  {/* show select combobox end */}
			  
			  <Row>
			  	{getSetData.map((item)=>{
						return(
							<>
								<Col>
									<div  className={`${StuDashBoardCss.Setsback}`} onClick={()=>{getAssessmentID(item.setID)}}>
										<div className={`${StuDashBoardCss.iconPrac}`}><img src={finalExam}/></div>
										<div  className={`${StuDashBoardCss.PracSetHeading}`}>{item.setDesc}</div>
									</div>
								</Col>
							</>
						)
					})}
			  </Row>

      </div>
      

	{/* Confirm Generate Assessment Popup start */}
		{showConfirmPop && 
			<div className={`${StuDashBoardCss.basePopConfirm}`}>
				<div className={`${StuDashBoardCss.innerWhiteBox}`} data-aos="zoom-in">
					<QuestionMarkIcon className={`${StuDashBoardCss.iconAreYouSure}`} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="900"/>
					<p className={`${StuDashBoardCss.messageTxt}`}>Are you sure generate this assessment ?</p>
					<button className='btn btn-success'>Yes !</button> {' '}
					<button onClick={()=>{setConfirmPop(false)}} className='btn btn-danger'>No !</button>
				</div>
			</div>
		}
	{/* Confirm Generate Assessment Popup end */}				

	{/* Loader start */}
		{showLoader &&
			<Loader/>
		}
	{/* Loader end */}
	{showErrPopup.status && 
	<div className={`${StuDashBoardCss.popupBack}`}>
		<div className={`${StuDashBoardCss.errBox}`}>
			<CloseIcon className={`${StuDashBoardCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
			<Alert severity="error">
				{showErrPopup.msg}
			</Alert>
		</div>       
	</div>
	}
    </div>
  );

  
}

export default Mock_CUET;