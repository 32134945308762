import React, { useState, useContext, useEffect } from "react";
import Header from '../guruAccount/Header';
import Login from '../guruAccount/Login';
import Footer from '../guruAccount/Footer';

import guru from "../../assets/guru-logo.png";
import guruname from "../../assets/vikas.png";
import gurupic from "../../assets/vikaspic.png";
import gurupic1 from "../../assets/vikaspic1.png";

import clip1 from '../../assets/portal-clip1.png';
import clip2 from '../../assets/portal-clip2.png';
import clip3 from '../../assets/portal-clip3.png';
import clip4 from '../../assets/portal-clip4.png';
import clip5 from '../../assets/portal-clip5.png';
import clip6 from '../../assets/portal-clip6.png';
import faqs from '../../assets/cuet-faq.png';
import icon1 from '../../assets/icon1.png';
import icon2 from '../../assets/icon2.png';
import icon3 from '../../assets/icon3.png';
import icon4 from '../../assets/icon4.png';

import { Button, Col, Container, Row } from "react-bootstrap";
// import { Link} from "react-router-dom";

import guruAccount from "../guruAccount/style.css";
import responsive from "../guruAccount/responsive.css";

//function Home() { 
const GuruHome = () =>{
 return (   
    <>
    <Header/>
    <Login/>
    <div className="banner-section gurupara p-2">
      <Container fluid>
          <Row className="justify-content-center"> 
            <Col md={2} className="text-center">  
              <img className="mw-100  img-thumbnail p-2" src={guru}/>
              {/* <div className="address my-2"> <b>LIVE CLASSES : </b> <a target="_blank" href="http://on-app.in/app/home?orgCode=lxmwr&referrer=utm_source%3Dcopy-link%26utm_medium%3Dtutor-app-referral"> <button type="button" class="btn btn-secondary btn-sm"> Click here</button> </a>   </div>                                 */}
            </Col> 
            <Col md={8}>  
                <p> <b>Dr. Vikas Vijay</b> is India's Leading Commerce Teachers and Students Trainer.<br/>
                Dr Vikas Vijay has worked as a PGT Commerce at the New Era Public School, New Delhi for a period of 15 years.
                He has also worked as a visiting lecturer at Delhi University.<br/>
                He is the author of several books in his subject. His best seller Books are Together with Accountancy and Business Studies which has received 
                huge demand among the student community. Dr Vikas has also shared his wide knowledge and expertise with the CBSE in the development of 
                Accountancy projects for Class XII. He has also worked with CIET,NCERT for promoting our Hon'ble Prime minister Shri Narender Modi Ji's grand 
                vision of "Entrepreneurial India".<br/>
                Recently Dr Vikas Vijay was Felicitated with Mentor of the Month Award by Amazon Book Bazar.He is also featured on the official page of Amazon 
                Book Bazar.<br/>  
                Dr. Vikas Vijay also received the Best Commerce Educator Award from Mr. Ashneer Grover, one of the judges of the first season of Shark Tank India and the founder of BharatPe.
                </p> 
            </Col> 
            <Col md={2}> 
              <div className="frame">      
                  <img className="mw-100" src={gurupic}/> 
              </div>       
            </Col>                     
          </Row>
          <Row className="justify-content-center">       
            <Col md={7} sm={12} className="text-center">
                  <span className="address"> 
                    <i class="fa fa-phone favi" aria-hidden="true"></i> <span>+91-9810278915</span></span>
                  <span className="address"> 
                    <i class="fa fa-envelope favi" aria-hidden="true"></i> <span> acctsguru4@gmail.com </span>
                  </span>       
            </Col>
            {/* <Col md={5} className="text-end pt-2">  
              <a target="_blank" href="https://bit.ly/3sjJbHm"><i class="fa fa-instagram favi"></i></a>      
              <a target="_blank" href="https://bit.ly/3P0ME6m"><i class="fa fa-facebook-square favi"></i></a>             
              <a target="_blank" href="https://bit.ly/45u2DiK"><i class="fa fa-twitter-square favi"></i></a>
              <a target="_blank" href="https://bit.ly/448oE5L"><i class="fa fa-user-circle-o favi"></i> </a>           
              <a target="_blank" href="https://bit.ly/3PpBQPe"><i class="fa fa-telegram favi"></i></a>
            </Col> */}
          </Row>
      </Container>
     </div>
  
{/* Clip Art Box */}
<div className="botton-banner"> “Learning Today, Leading Tomorrow.” </div>  

   <div className="cuet-portal">
        <div className="portalBox">
          <Row>     
            <Col md={4} sm={6}>
              <div className="portalBox1">
                <div className="portalBox-image"> <img src={clip1}/> </div>
                <h4> Quick Notes </h4>
                <p>100% authentic content for brushing up the contents studied. </p>          
              </div>
            </Col>
            <Col md={4} sm={6}>
              <div className="portalBox1">
                <div className="portalBox-image"> <img src={clip2}/> </div>
                <h4> Topic/ Chapter-wise Practice  </h4>
                <p> More than 10,000 questions for self-practice to make the preparation process more dynamic & enjoyable. </p>          
              </div>
            </Col>
            <Col md={4} sm={6}>
              <div className="portalBox1">
                <div className="portalBox-image"> <img src={clip3}/> </div>
                <h4> Practice Sets </h4>
                <p> Easy access to a wealth of practice sets to enhance overall readiness for the exam. </p>          
              </div>
            </Col>
            <Col md={4} sm={6}>
              <div className="portalBox1">
                <div className="portalBox-image"> <img src={clip4}/> </div>
                <h4> Self-Generated Practice Exams </h4>
                <p> Study at your own pace and convenience, create personalized study schedules as per your learning style. </p>          
              </div>
            </Col>
            <Col md={4} sm={6}>
              <div className="portalBox1">
                <div className="portalBox-image"> <img src={clip5}/> </div>
                <h4> Mock CUET Exams </h4>
                <p> CUET Online Portal ensures quality exam preparation making it easier to secure a seat in your dream college. </p>          
              </div>
            </Col>
            <Col md={4} sm={6}>
              <div className="portalBox1">
                <div className="portalBox-image"> <img src={clip6}/> </div>
                <h4> Report </h4>
                <p> Track Real-Time Progress to identify strengths & weaknesses, allowing for targeted improvements before actual CUET exam. </p>          
              </div>
            </Col>
          </Row>  
        </div>
   </div>
{/* Clip Art Box */}
{/* Online Portal */}
   <div className="online-portal">
      <Row className="d-flex justify-content-center">
        <Col md="6" sm="8" className="largeSpace">
          <h2> Why should you opt</h2>
          <h2> for <span className="largeFont">CUET UG-2025</span></h2> 
          <h2><span className="largeFont">ONLINE</span> Portal?</h2>
        </Col>    
        <Col md="4" sm="4"><img src={faqs}/></Col>  
      </Row>
   </div>
{/* Online Portal */}
{/* icon box section */}
   <div className="iconBox">
      <Row>
        <Col md="3" sm="6">
          <div className="iconSection">
            <div className="iconOne"><img src={icon1}/></div>
            <h3> Convenient  </h3>
            <p> Learn at your own pace, Anytime, Anywhere. </p>
          </div>       
        </Col>
        <Col md="3" sm="6">
          <div className="iconSection">
            <div className="iconOne icon2"><img src={icon2}/></div>
            <h3> Easy to use</h3>
            <p> Navigate the content available on portal for better learning experience. </p>
          </div>       
        </Col>
        <Col md="3" sm="6">
          <div className="iconSection">
            <div className="iconOne"><img src={icon3}/></div>
            <h3> Assess Yourself </h3>
            <p> Monitor application status online. </p>
          </div>       
        </Col>
        <Col md="3" sm="6">
          <div className="iconSection">
            <div className="iconOne icon2"><img src={icon4}/></div>
            <h3> Instant Updates  </h3>
            <p> Check the Reports of the attempted tests immediately. </p>
          </div>       
        </Col>
      </Row>
   </div>
{/* icon box section */}
{/* Subjects Covered box */}
<br></br>
   <div className="botton-banner"> Yes, Now You're Ready to enter in the Gateway of Your Dreams! </div>
   <div className="iconBox">

    <Row>
          <Col md="2">
            <div className="subjects-box">
              <div className="section-bar"> Language</div>
              <div className="subject-name"> English </div>
            </div>
          </Col>
          <Col md="8">
            <div className="subjects-box">
              <div className="section-bar"> Domain Specific Subjects</div>
              <Row>
                <Col xl="4" sm="6">
                  <ul className="subList">
                    <li> Mathematics </li>
                    <li> Physics </li>
                    <li> Chemistry </li>
                    <li> Biology/ Biological Science/ Biotechnology/Biochemistry </li>
                    <li> Accountancy  </li>
                    <li> Business Studies </li>
                    <li> Sociology </li>   
                  </ul>
                </Col>
                <Col xl="4" sm="6">
                  <ul className="subList">
                    <li> Economics/Business Economics </li>
                    <li> Geography/Geology</li>
                    <li> Political Science</li>
                    <li> History</li>
                    <li> Physical Education(Yoga, Sports) </li>
                    <li> Computer Science/Informatics Practices</li>  
                    <li> Psychology </li>  
                    {/* <li> Engineering Graphics </li>                */}
                  </ul>
                </Col>
                <Col xl="4" sm="6">
                  <ul className="subList">
                    {/* <li> Entrepreneurship </li> */}
                    <li> Fine Arts/Visual Arts / Commercial Arts  </li>
                    <li> Home Science </li>
                    <li> Mass Media and Mass Communication   </li>
                    <li> Performing Arts(Dance,Drama,Music) </li>
                    <li> Sanskrit </li> 
                    
                    {/* <li> Legal Studies </li>                */}
                  </ul>
                </Col>
                
              </Row>
            </div>
          </Col>
          <Col md="2">
            <div className="subjects-box">
              <div className="section-bar"> General Aptitude Test</div>
              <div className="subject-name"> General Aptitude Test </div>
            </div>
          </Col>
        </Row>
    
   </div>
{/* Subjects Covered box */}
  <Footer/>
    </>
  );
}
export default GuruHome;
