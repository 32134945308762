import React, { useEffect } from "react";
import Header from '../homePage/Header';
import Footer from '../homePage/Footer';

import AOS from "aos";
import "aos/dist/aos.css";

import { Container, Row , Col } from "react-bootstrap";

import layout from "../layout/innerPage.css";
import innerResp from "../layout/innerResp.css";

const Products = () => {

  useEffect( () =>{
    AOS.init({
      offset:100,
      duration:3000,
    });
  },[] );

 return (   
    <>
    <Header/>
      <div className="contactSection" data-aos="fade-right">
        <Container>
          <Row>
            <Col md={12}>
              <h1 style={{margin: "4px 0"}}> Products </h1>                     
            </Col>            
          </Row>         
        </Container>
      </div> 
      <Container>
        <Row>
        <Col md={12} data-aos="fade-left">
              <div className="Product-section">
                <h2> Language </h2>   
                <Row className="productSub">
                  <Col md={8} xs={6}>
                    <h4>Subject</h4>
                    <p> English</p>
                    </Col>
                  <Col md={4} xs={6}>
                    <h4>Rate (Rs.)</h4>
                    <p>	300.00 </p>
                    </Col>
                </Row>           
              </div>             
            </Col>
            <Col md={12} data-aos="fade-right">
              <div className="Product-section">
                <h2> Domain Specific Subjects</h2>   
                <Row className="productSub">
                  <Col md={8}>
                    <h4>Subject</h4>
                    <p> Mathematics </p>
                    <p> Physics </p>
                    <p> Chemistry </p>
                    <p> Biology/Biological Studies/Biotechnology/Biochemistry </p>
                    <p> Accountancy </p>
                    <p> Business Studies </p>
                    <p> Economics/Business Economics </p>
                    <p> History </p>
                    <p> Geography/Geology </p>
                    <p> Political Science </p>
                    <p> Computer Science/Informatics Practices </p>
                    </Col>
                  <Col md={4}>
                    <h4>Rate (Rs.)</h4>
                    <p>1 Subject = Rs. 300</p>
                    <p>2 Subjects = Rs. 250 per subject </p>
                    <p>3 Subjects = Rs. 200 per subject </p>
                    <p>More than 3 Subjects = Rs. 175 per subject </p>

                    </Col>
                </Row>           
              </div>             
            </Col>
            <Col md={12} data-aos="fade-up">
              <div className="Product-section">
                <h2> General Aptitude Test </h2>   
                <Row className="productSub">
                  <Col md={8} xs={6}>
                    <h4>Subject</h4>
                    <p>General Aptitude Test</p>
                    </Col>
                  <Col md={4} xs={6}>
                    <h4>Rate (Rs.)</h4>
                    <p>	300.00 </p>
                  </Col>
                </Row>           
              </div>             
            </Col>
        </Row>
      </Container>
     <Footer/>
    </>
  );
}
export default Products;
