import React,{useEffect,useState} from 'react';
import RegisterCss from './Register.module.css';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';
import { useLocation } from "react-router";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import {apiBaseURL,apiURL,apiKey,baseURL} from '../../constant';
//import PaytmButton  from './../../paytm-button/paytmButton';

import {Col, Container, Row } from "react-bootstrap";

import guru from "../../assets/guru-res.png";

export default function AccountsguruRegister() {
    
    const location = useLocation()
    let promoCode ="";
    let discount = "";
    let stuLimit = "";

    if(location.state==null){
        promoCode="";
    }else{
        promoCode= location.state;
        discount = promoCode.promoCodeData[0]['partyDiscount'];
        stuLimit = promoCode.promoCodeData[0]['totalLimit'];
    }


    
    const [show, setShow] = useState(false);
    const [isLoaderActive, setIsLoaderActive] = useState(true);
    const [isSignupDone, setIsSignupDone] = useState(false);
    const [isPaymentStart, setIsPaymentStart] = useState(false);
    const [isPaymentProcessCompleated, setIsPaymentProcessCompleated] = useState(false);
    const [userDetailsPayment, setUserDetailsPayment] = useState();
    const [isError, setIsError] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [transactionAmount , setTransactionAmount] = useState();
    const [transactionData , setTransactionData] = useState();
    const [selectedSection, setSelectedSection] = useState([]); 
    const [postDataSend , setPostDataSend] = useState({});
    const [recCustID , setRecCustID] = useState({});
    const [recUserID , setRecUserID] = useState({});
    const[showErrPopup, setErrPopup] =useState({status:false, msg:null});
    const [studentLimit, setStudentLimit] =  useState()
    const [signupFormData, setSignupFormData] = useState({ 
        values:{ name:"", 
        fatherName:"", 
        address:"", 
        stateID:"", 
        pincode:"", 
        contactNo:"",
        email:"",
        amountExamSectionA:0,
        amountExamSectionB:0,
        amountExamSectionC:0,
        totalAmount:0},        
        selectedSections:{},
        subjectSelection:{}
    });    

    // console.log(signupFormData.values.totalAmount)
    const [responceData, setResponceData] = useState({ 
        userRefID:0,
        orderDetail:{},        
        bankTransID:"",
        transactionAmount:0,
        payMode:1,
        orderType:1,
        remarks:"",
        status:1,
    });
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);    
    let amountExamSectionA = 0;
    let amountExamSectionB = 0;
    let amountExamSectionC = 0;

    const[totalAmountAC, setTotalAmountAC] = useState(0)
    const[totalDiscountedAmount, setTotalDiscountedAmount] = useState(0)

    useEffect( ()=>{
        //resetForm()
        const fetchOptions = {
            method: 'GET',
            headers:{ 'key': apiKey }
        };       

        fetch(apiBaseURL+apiURL.getStateList,fetchOptions )
            .then(response => response.json())
            .then( 
                (result)=>{
                    if(result.status=='success'){
                        setIsLoaderActive(false);
                        setStateList( result.data );
                    }else{
                        setIsError(true);
                        console.log(apiURL.getStateList+" API Error : "+result.message);
                    }
                }
            )
            .catch(
                (error)=>{
                    console.log(error);
                }
            );        
        fetch(apiBaseURL+apiURL.getSectionList,fetchOptions )
            .then(response => response.json())
            .then( 
                (result)=>{
                    if(result.status=='success'){                        
                        setSectionList( result.data.sections );                        
                    }else{
                        console.log(apiURL.getSectionList+" API Error : "+result.message);
                    }
                }
            )
            .catch(
                (error)=>{
                    console.log(error);
                }
            );


            if(promoCode.accessCode!=undefined || promoCode.accessCode!=""){
                const promoCodeData = {
                    method: 'POST',
                    headers:{ 'key': apiKey },
                    body:JSON.stringify({"promoCode": promoCode.accessCode})
                };
                fetch(apiBaseURL+apiURL.getCountPromoCode,promoCodeData )
                    .then(response => response.json())
                    .then( 
                        (result)=>{
                            if(result.status=='success'){
                                setStudentLimit(result.data)
                            }else{
                                alert(result.message)
                            }
                        }
                    )
                    .catch(
                        (error)=>{
                            console.log(error);
                        }
                );
            }
            
    },[] );

    const signupHandleOnChange = e => setSignupFormData( (prev)=>{
        return {...prev, values:{...prev.values,[e.target.id] : e.target.value} }
    });
function selSection(section,status){
    if(selectedSection.length==0){
        selectedSection.push(section);
    }else{
        if(selectedSection.indexOf(section)==-1)
        {
            selectedSection.push(section);
        }else{
            if(status==false)
            {                
                selectedSection.splice(selectedSection.indexOf(section),1);
            }
        }
    } 
    setSelectedSection(selectedSection); 
    console.log(selectedSection);  
}
    const signupHandleOnCheck = e => setSignupFormData( (prev)=>{               
        //console.log();
        
        let sectionId = e.target.getAttribute('data-section-id');
        getSubjectList(sectionId);        
        //let selectedSectionArr = [];
        selSection(sectionId,e.target.checked);
        if(e.target.id=='section2'){            
            amountExamSectionA = prev.values.amountExamSectionA;
            amountExamSectionB = prev.values.amountExamSectionB;
            amountExamSectionC = prev.values.amountExamSectionC;
            
            if(e.target.checked==false ){
                setTotalDiscountedAmount(0);
                return {...prev, 
                    values:{...prev.values,[e.target.id] : e.target.checked, amountExamSectionB : 0 ,totalAmount: prev.values.amountExamSectionA },
                    subjectSelection:{}
                }
            }else{                
                return {...prev,                     
                    selectedSections:{...prev.selectedSections, [e.target.id] : e.target.checked}
                }

            }
        }else{
            /*code added by Ram Dwivedi*/
            amountExamSectionA = prev.values.amountExamSectionA;
            amountExamSectionB = prev.values.amountExamSectionB;
            amountExamSectionC = prev.values.amountExamSectionC;                     
            let totalAmount = prev.values.totalAmount;
            let sectionPrice = e.target.getAttribute('data-section-price');
            //alert(e.target.id+"----"+e.target.checked);
            if(e.target.id=='section1'){
                if(e.target.checked){
                    amountExamSectionA = parseInt(sectionPrice);
                    
                    setTotalAmountAC(Number(totalAmountAC)+Number(parseInt(sectionPrice))); 

                }else{
                    amountExamSectionA = 0;
                    if(totalAmountAC!=0)
                    {
                        setTotalAmountAC(Number(totalAmountAC)-Number(parseInt(sectionPrice)));
                    }                    
                }
                return {...prev, 
                        values:{...prev.values,
                            amountExamSectionA : amountExamSectionA, totalAmount: (amountExamSectionA + amountExamSectionB + amountExamSectionC) },
                            selectedSections:{...prev.selectedSections, [e.target.id] : e.target.checked}
                }
            }else{
                if(e.target.checked){
                    amountExamSectionC = parseInt(sectionPrice);  
                    setTotalAmountAC(Number(totalAmountAC)+Number(parseInt(sectionPrice)));                   
                }else{
                    amountExamSectionC = 0;
                    if(totalAmountAC!=0)
                    {
                        setTotalAmountAC(Number(totalAmountAC)-Number(parseInt(sectionPrice)));
                    }                    
                }

                return {...prev, 
                        values:{...prev.values,amountExamSectionC : amountExamSectionC, totalAmount: (amountExamSectionA + amountExamSectionB + amountExamSectionC) },
                        selectedSections:{...prev.selectedSections, [e.target.id] : e.target.checked}
                }
            }
            /*code added by Ram Dwivedi*/
        }                            
    });
    const handleCloseSetUncheck = e => setSignupFormData( (prev)=>{
        return {...prev, 
            values:{...prev.values, examSectionB : false, amountExamSectionB : 0 ,totalAmount: prev.values.amountExamSectionA }
        }
    });
    const signupHandleOnSubjectCheck = e => setSignupFormData((prev)=>{

        let amountExamSectionA = prev.values.amountExamSectionA;
        let amountExamSectionB = prev.values.amountExamSectionB;
        let amountExamSectionC = prev.values.amountExamSectionC;
        let subjectPrice = e.target.getAttribute('data-subjectprice');
        
        let subjectID = e.target.getAttribute('data-subjectid');

        if(e.target.checked){
            amountExamSectionB = parseInt(amountExamSectionB) + parseInt(subjectPrice);
            setTotalDiscountedAmount(totalDiscountedAmount + Number(parseInt(subjectPrice)));
        }else{
            amountExamSectionB = parseInt(amountExamSectionB) - parseInt(subjectPrice);
            setTotalDiscountedAmount(totalDiscountedAmount - Number(parseInt(subjectPrice)));
        }
       
        //console.log('totalAmount->'+(amountExamSectionA + amountExamSectionB + amountExamSectionC));
        return {...prev, 
            values:{...prev.values,amountExamSectionB : amountExamSectionB, totalAmount: (amountExamSectionA + amountExamSectionB + amountExamSectionC) },
            subjectSelection:{...prev.subjectSelection, [subjectID] : e.target.checked}
        }
        
    });

    function getSubjectList(section)
    {
        if(section==2)
        {        
            const fetchOptionsPost = {
                method: 'POST',
                headers:{ 'key': apiKey },
                body:JSON.stringify({"section": section})
            };
            fetch(apiBaseURL+apiURL.getSubjectsOfSection,fetchOptionsPost )
                .then(response => response.json())
                .then( 
                    (result)=>{
                        if(result.status=='success'){
                            setSubjectList( result.data.subjects );
                        }else{
                            console.log(apiURL.getSubjectsOfSection+" API Error : "+result.message);
                        }
                    }
                )
                .catch(
                    (error)=>{
                        console.log(error);
                    }
            );
        }
    }     
    function signup(e){ 
        e.preventDefault(); 
        setIsLoaderActive(true); 
        setPostDataSend({});
        const secVal = signupFormData.selectedSections;        
        const selectedSections = Object.keys(secVal).map((key) => secVal[key]==true?key.replace('section',''):'');

        const finalSelectedSections = selectedSections.filter(word => word != "");
        if(finalSelectedSections.length<1)
        {
            // alert("Please select section first.");
            setErrPopup((prev)=>{
                return {...prev, status:true, msg:"Please select section first."}
              })
            setIsLoaderActive(false); 
        }else{        
            const subVal = signupFormData.subjectSelection;
            const selectedSubjects = Object.keys(subVal).map((key) => subVal[key]==true?key:'');
            const finalSelectedSubjects = selectedSubjects.filter(word => word != ""); 
            /*Discount Calculator*/
            var totalNoOfSubjects = finalSelectedSubjects.length;
            var perSubjectPrice = 0;
            if(totalNoOfSubjects==1)
            {
                perSubjectPrice = 300;
            }else if(totalNoOfSubjects==2){
                perSubjectPrice = 250;
            }else if(totalNoOfSubjects==3){
                perSubjectPrice = 200;
            }else if(totalNoOfSubjects>3){
                perSubjectPrice = 175;
            }
            /*Discount Calculator*/
            let orderDetail_res = "";
            orderDetail_res += "{";
            finalSelectedSections.map((val)=>{
                
                if(val==2)
                {
                    orderDetail_res += '"'+val+'": {"amount": "'+(totalNoOfSubjects*perSubjectPrice)+'",';
                    orderDetail_res += '"subjects":';
                    orderDetail_res += '{';                
                    finalSelectedSubjects.map((subVal,index)=>{                    
                        // orderDetail_res += '"'+(index+1)+'": {"amount": '+subjectList[index].subjectPrice+',"subjectName": "'+subjectList[index].subjectName+'"},';
                        orderDetail_res += '"'+(index+1)+'": {"amount": "'+perSubjectPrice+'","subjectName": "'+subjectList[index].subjectName+'"},';
                    })                
                    orderDetail_res += '},';
                }else{
                    orderDetail_res += '"'+val+'": {"amount": "'+sectionList[val-1].sectionPrice+'",';
                }
                orderDetail_res += '"sectionName": "'+sectionList[val-1].sectionName+'"},';            
            })
            orderDetail_res += "}";
            let orderDetailFinal = orderDetail_res.replaceAll('},}','}}'); 
            let totalAmountAfterDiscount = (promoCode.accessCode != "" || promoCode.accessCode != undefined) ? (Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount)))-((Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount)))/100)*discount : Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount));
           
            let postData = {
                "stuName":signupFormData.values.name,
                "fatherName":signupFormData.values.fatherName,
                "address":signupFormData.values.address,
                "stateID":signupFormData.values.stateID,
                "pinCode":signupFormData.values.pincode,
                "contact":signupFormData.values.contactNo,
                "email":signupFormData.values.email,
                "sectionIDs":finalSelectedSections,
                // "totalAmount":signupFormData.values.totalAmount,
                "totalAmount":totalAmountAfterDiscount,
                "subjectIDs":finalSelectedSubjects,
                "orderDetails":orderDetailFinal,
                "orderFrom":"accountsguru",
                "promoCode" : promoCode.accessCode!= undefined  ? promoCode.accessCode : "",
            } 
            setPostDataSend(postData);

            if((promoCode.accessCode!="" && promoCode.accessCode!=undefined)){
                if(studentLimit < stuLimit){
                    fetch(apiBaseURL+apiURL.studentRegistration, {
                        method: 'POST',
                        headers:{ 'key': apiKey },
                        body: JSON.stringify(postData)
                    })
                    .then(response => response.json())
                    .then( (result)=>{
                        setIsLoaderActive(false);
                        if( result.status=='success' ){
                            setIsPaymentStart(true);                                 
                            setUserDetailsPayment(result.data.userRefID);
                            setResponceData((prev)=>{
                                return {...prev, userRefID:result.data.userRefID,orderDetail:JSON.parse(orderDetailFinal)}
                            });
                            //console.log(responceData);
                            setRecCustID({'recCustID':result.data.userRefID}) 
                            setRecUserID({'recUserCode':result.data.orderID})
        
                        }else{                    
                            // alert(result.message); 
                            setErrPopup((prev)=>{
                                return {...prev, status:true, msg:result.message}
                            })
        
                            setTotalAmountAC(0);
                            setTotalDiscountedAmount(0);
                            setSignupFormData((prev)=>{
                            return {...prev,values:{...prev.values,amountExamSectionA:0,amountExamSectionB:0,amountExamSectionC:0,totalAmount:0},
                            selectedSections:{},
                            subjectSelection:{}}}
                        );                             
                        }                
                    })
                    .catch( (err)=>{
                        // alert("Registration Failed. Please retry"); 
                        setErrPopup((prev)=>{
                            return {...prev, status:true, msg:"Registration Failed. Please retry"}
                        })
                    }); 
                }else{
                    setIsLoaderActive(false);
                    resetForm()
                    setErrPopup((prev)=>{
                        return {...prev, status:true, msg:"You Can`t Registered More Then Limit. Try Again "}
                    })
                }
            }else{
                fetch(apiBaseURL+apiURL.studentRegistration, {
                    method: 'POST',
                    headers:{ 'key': apiKey },
                    body: JSON.stringify(postData)
                })
                .then(response => response.json())
                .then( (result)=>{
                    setIsLoaderActive(false);
                    if( result.status=='success' ){
                        setIsPaymentStart(true);                                 
                        setUserDetailsPayment(result.data.userRefID);
                        setResponceData((prev)=>{
                            return {...prev, userRefID:result.data.userRefID,orderDetail:JSON.parse(orderDetailFinal)}
                        });
                        //console.log(responceData);
                        setRecCustID({'recCustID':result.data.userRefID}) 
                        setRecUserID({'recUserCode':result.data.orderID})
    
                    }else{                    
                        // alert(result.message); 
                        setErrPopup((prev)=>{
                            return {...prev, status:true, msg:result.message}
                        })
    
                        setTotalAmountAC(0);
                        setTotalDiscountedAmount(0);
                        setSignupFormData((prev)=>{
                        return {...prev,values:{...prev.values,amountExamSectionA:0,amountExamSectionB:0,amountExamSectionC:0,totalAmount:0},
                        selectedSections:{},
                        subjectSelection:{}}}
                    );                             
                    }                
                })
                .catch( (err)=>{
                    // alert("Registration Failed. Please retry");
                    setErrPopup((prev)=>{
                        return {...prev, status:true, msg:"Registration Failed. Please retry"}
                    })
                }); 
            }
            
        }       
    };

    function paymentWithPaytm()
    { 
        let totalAmountAfterDiscount = (promoCode.accessCode != "" || promoCode.accessCode != undefined) ? Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount))-(((Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount)))/100)*discount) : Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount));

        //alert(totalAmountAfterDiscount);
        let url = baseURL+"paytm-payment/index.php?txnAmount="+totalAmountAfterDiscount+"&custId="+recCustID.recCustID+"&orderId="+recUserID.recUserCode+"&mobileNo="+postDataSend.contact+"&email="+postDataSend.email;
        //window.location = url;
        window.location.href = url;
    }
    function paymentWithCCAvenue()
    {
        //alert(totalAmountAfterDiscount);
        let totalAmountAfterDiscount = (promoCode.accessCode != "" || promoCode.accessCode != undefined) ? Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount))-(((Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount)))/100)*discount): Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount));
        let url = baseURL+"ccavenue-payment/index.php?cusName="+postDataSend.stuName+"&cusAddress="+postDataSend.address+"&cusCity="+postDataSend.stateID+"&cusState="+postDataSend.stateID+"&cusPin="+postDataSend.pinCode+"&txnAmount="+totalAmountAfterDiscount+"&custId="+recCustID.recCustID+"&orderId="+recUserID.recUserCode+"&mobileNo="+postDataSend.contact+"&email="+postDataSend.email;
        //window.location = url;
        window.location.href = url;
    }

    function paymentStatusPaytm(paymentStatus){        
        
        setTransactionAmount(paymentStatus.TXNAMOUNT)
        setTransactionData(paymentStatus);
        //console.log(paymentStatus);
        let paytmStatus = 0;
        if(paymentStatus.STATUS=="TXN_SUCCESS")
        {
            paytmStatus = 1;
        }else{
            paytmStatus = 0;
        }        
        const RepData = {
            "userRefID":responceData.userRefID,
            "orderDetail":responceData.orderDetail,        
            "bankTransID":paymentStatus.BANKTXNID,
            "transactionAmount":paymentStatus.TXNAMOUNT,
            "payMode":1,
            "orderType":1,
            "remarks":"",
            "status":paytmStatus
        }
        fetch(apiBaseURL+apiURL.paymentResponse, {
            method: 'POST',
            headers:{ 'key': apiKey },
            body: JSON.stringify(RepData)
        })
        .then(response => response.json())
        .then( (result)=>{
            console.log(result);
            setIsLoaderActive(true);
            if( result.status=='success' ){
                setIsPaymentStart(false); 
                setIsPaymentProcessCompleated(true);  
                setIsLoaderActive(false);
                document.getElementById("paytm-checkoutjs").style.display = "none";                                           
            }else{
                setErrPopup((prev)=>{
                    return {...prev, status:true, msg:"Registration Failed. Please retry"}
                })
                // alert("Registration Failed. Please retry");
            }
        })
        .catch( (err)=>{
            setErrPopup((prev)=>{
                return {...prev, status:true, msg:"Registration Failed. Please retry"}
            })
            // alert("Registration Failed. Please retry");
        });
    };
    function resetForm()
    {               
        var checkboxes = document.getElementsByName('sectionCheck');
        for (var checkbox of checkboxes) {
            checkbox.checked = false;
        }
        setTotalAmountAC(0);
        setTotalDiscountedAmount(0);
        setSignupFormData((prev)=>{
            return {
                values:{ name:"", 
                            fatherName:"", 
                            address:"",
                            stateID:"", 
                            pincode:"", 
                            contactNo:"",
                            email:"",
                            amountExamSectionA:0,
                            amountExamSectionB:0,
                            amountExamSectionC:0,
                            totalAmount:0},        
                            selectedSections:{},
                            subjectSelection:{}
                }}
        );
        setSelectedSection([]);                        
    }
    function totalAmountLogic(totAmount)
    {
        if(totAmount==0)
        {
            return totAmount;
        }else if(totAmount==300)
        {
            return totAmount;
        }else if(totAmount==600)
        { 
            return 500;
        }else if(totAmount==900)
        { 
            return 600;
        }else if(totAmount>900){  
            return ((totAmount/300)*175)
        }
    }    
    

    return (
        <div>
            
            <div className={`${RegisterCss.container1}`}>

                <Row className='justify-content-center'>
                    <Col md={12} className='text-center'>    
                        <img className="mw-100 p-2 text-center" src={guru}/>
                    </Col>
                </Row>
            
                <div className={`${RegisterCss.mainDiv}`}>
                    
                    <div className={`${RegisterCss.regImgBox}`}></div>
                    
                    <div className={`${RegisterCss.regformBox}`}>

                        { isLoaderActive && <div className={RegisterCss.isLoader}>
                            <div className="spinner-border text-primary" role="status"></div>
                        </div> }

                        { isSignupDone && <div className={RegisterCss.signupSuccess}>
                            <h1>Registration Successful</h1>
                            <p>Thank you for registring with us. Please check your email inbox to get your login credentials.</p>
                        </div>
                        }

                        { isPaymentStart && <div className={RegisterCss.signupSuccess}>
                            <h1>Payment Methods</h1>
                            <p style={{"fontSize":"22px","marginTop":"50px"}}>Please select the payment gateway to start the payment process.</p>
                            {/* <PaytmButton btnValue={signupFormData.values.totalAmount} paymentStatusPaytm={paymentStatusPaytm}/> */}
                            <button type="button" className={`${RegisterCss.logButtonPaytm}`} onClick={paymentWithPaytm} ><span style={{"color": "#052E70"}}>PAY</span><span style={{"color": "#00BBF2"}}>TM</span></button>
                            
                            {/* ---------------- / Ccavenue \ -------------------------- */}
                            {/* <button type="button" className={`${RegisterCss.logButtonPaytm}`} onClick={paymentWithCCAvenue}  style={{"marginTop": "10px"}}><span style={{"color": "#157BC5"}}>CC</span> <span style={{"color": "#161415"}}>Avenue</span></button> */}
                            {/* ---------------- \ Ccavenue / -------------------------- */}

                            {/* <PaytmButton btnValue={signupFormData.values.totalAmount} paymentStatusPaytm={paymentStatusPaytm}/> */}
                        </div>
                        }

                        { isPaymentProcessCompleated && <div className={RegisterCss.signupSuccess}>
                            <h1>Payment Details</h1>
                            <p>Payment proccess have been compleated. Please check the details below.</p>
                            <table className={RegisterCss.signupSuccessTable}>
                                <thead>
                                    <tr>
                                        <td className={RegisterCss.signupSuccessTableHeading}>Name</td><td className={RegisterCss.signupSuccessTableHeading}>Value</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Bank Transaction ID</td><td>{transactionData.BANKTXNID}</td>
                                    </tr>
                                    <tr>
                                        <td>Payment Status</td><td>{transactionData.STATUS}</td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Amount</td><td>{transactionData.TXNAMOUNT}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>Your login information have been sent to your email id. Please check and login.</p>
                            <a href="/" className={`${RegisterCss.logButtonSignup}`} >Sign in</a>                           
                        </div>
                        }

                        { !isLoaderActive && !isSignupDone && !isPaymentStart && !isPaymentProcessCompleated && <>
                            <div className={`${RegisterCss.head}`}>
                                <h1>Registration Form</h1>
                            </div>
                            <form onSubmit={signup} >

                                <label className={`${RegisterCss.lableHead}`}>Name</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`} 
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={signupFormData.values.name}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter your full name'
                                        autoComplete='off'
                                        required 
                                    />
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>Father's Name</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`}  
                                        type="text"
                                        name='fatherName' 
                                        id="fatherName"
                                        value={signupFormData.values.fatherName}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter father name'
                                        autoComplete='off'
                                        required
                                    />
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>Address</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    {/* <input className={`${RegisterCss.inputText}`} autoComplete='off' type="text" required name='User name' placeholder='Enter your address' /> */}
                                    <input 
                                        className={`${RegisterCss.inputText}`}  
                                        type="text"
                                        name='address' 
                                        id="address"
                                        value={signupFormData.values.address}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter Your Address'
                                        autoComplete='off'
                                        required
                                    />
                                </div>

                                <div className={`${RegisterCss.inputRow}`}>
                                    <div className={`${RegisterCss.AddState}`}>
                                        <label className={`${RegisterCss.lableHead}`}>State</label>

                                        <div className={`${RegisterCss.inputBox}`}>
                                            <select 
                                                className={`${RegisterCss.selectcombobox}`} 
                                                name="stateID"
                                                id="stateID"
                                                value={signupFormData.values.stateID}
                                                onChange={signupHandleOnChange}
                                                autoComplete='off'
                                                required    
                                            >
                                                <option value="">Select State</option>
                                                {stateList
                                                    .map( 
                                                        stateDetail => ( <option key={stateDetail.stateID} value={stateDetail.stateID}>{stateDetail.stateNameLang1}</option> ) 
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className={`${RegisterCss.pinCode}`}>
                                            <label className={`${RegisterCss.lableHead}`}>PIN Code</label>
                                            <div className={`${RegisterCss.inputBox}`}>
                                                <input 
                                                    className={`${RegisterCss.inputText}`} 
                                                    type="number" 
                                                    name='pincode' 
                                                    id='pincode'
                                                    min={100000}
                                                    max={999999}
                                                    value={signupFormData.values.pincode} 
                                                    onChange={signupHandleOnChange}
                                                    placeholder='Enter PIN code'
                                                    autoComplete='off' 
                                                    required 
                                                />
                                            </div>
                                    </div>
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>Contact No.</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`}  
                                        type="number" 
                                        name='contactNo' 
                                        id='contactNo' 
                                        value={signupFormData.values.contactNo}
                                        onChange={signupHandleOnChange}
                                        placeholder='Enter your contact no.'
                                        autoComplete='off'
                                        required 
                                    />
                                </div>

                                <label className={`${RegisterCss.lableHead}`}>E-mail id</label>
                                <div className={`${RegisterCss.inputBox}`}>
                                    <input 
                                        className={`${RegisterCss.inputText}`} 
                                        type="email" 
                                        name='email' 
                                        id='email'
                                        value={signupFormData.values.email}
                                        onChange={signupHandleOnChange} 
                                        placeholder='Enter your E-mail id'
                                        autoComplete='off' 
                                        required 
                                    />
                                </div>

                                <div className={`${RegisterCss.inputRow}`}>
                                    <div className={`${RegisterCss.addsub}`}>
                                        <label className={`${RegisterCss.lableHead}`}>Select Subject</label>
                                        <div className={`${RegisterCss.rowSection}`}>
                                            {sectionList.map((sectionDetail,index) =>{                                           
                                                    
                                                return(
                                                    <label htmlFor={'section'+sectionDetail.sectionID} className={`${RegisterCss.containerSe}`} key={sectionDetail.sectionID}>{sectionDetail.sectionName}                                                            
                                                        <input
                                                            type="checkbox"
                                                            // name={'section'+sectionDetail.sectionID}
                                                            name={'sectionCheck'}
                                                            id={'section'+sectionDetail.sectionID}
                                                            defaultValue={true}                                                                
                                                            data-section-price = {sectionDetail.sectionPrice}
                                                            data-section-id = {sectionDetail.sectionID}
                                                            onChange={ (e)=>{ sectionDetail.isChoosable==1 && e.target.checked==true? setShow(true) : setShow(false);signupHandleOnCheck(e) } }
                                                            checked={signupFormData.selectedSections[sectionDetail.sectionID]}
                                                        />
                                                        <span className={`${RegisterCss.checkmark}`}></span>                                                            
                                                        {sectionDetail.sectionID!="2" &&
                                                            (<div className={`${RegisterCss.StatusTextMrp}`}>MRP - {sectionDetail.sectionPrice} INR</div>)
                                                        }                                                           
                                                    </label>
                                                )                                                                                             

                                            })}
                                        </div>
                                    </div>
                                    {show && 
                                        
                                         <Modal show={show} onHide={ (e)=>{handleClose(e);handleCloseSetUncheck(e) } } backdrop="static" keyboard={false}>
                                         <Modal.Header className={`${RegisterCss.headerSltsubject}`} closeButton>
                                             <Modal.Title>Select Subject</Modal.Title>
                                         </Modal.Header>
                                         <Modal.Body className={`${RegisterCss.SubjectList}`}>
                                             {subjectList.map((subjectDetail) => {
                                                 if(subjectDetail.subjectID<=13 || 
                                                    subjectDetail.subjectID==15 || 
                                                    subjectDetail.subjectID==16 || 
                                                    subjectDetail.subjectID==17 ||
                                                    subjectDetail.subjectID==18 || 
                                                    subjectDetail.subjectID==19 || 
                                                    subjectDetail.subjectID==20 ||
                                                    subjectDetail.subjectID==21 || 
                                                    subjectDetail.subjectID==22)  
                                                 {                                                           
                                                     return(<label className={`${RegisterCss.containerSe}`} key={"sectionBsub"+subjectDetail.subjectID}>
                                                         <input
                                                             
                                                             type="checkbox" 
                                                             name={"sectionBsub"+subjectDetail.subjectID} 
                                                             id={"sectionBsub"+subjectDetail.subjectID}
                                                             data-subjectid={subjectDetail.subjectID}
                                                             data-subjectprice={subjectDetail.subjectPrice}
                                                             key={subjectDetail.subjectAbbreviation}
                                                             defaultValue={false}
                                                             onChange={ (e)=>{signupHandleOnSubjectCheck(e);} }
                                                         />
                                                         <span className={`${RegisterCss.checkmark}`}></span>{subjectDetail.subjectName} 
                                                         {/* <div className={`${RegisterCss.StatusTextMrp}`}>MRP - {subjectDetail.subjectPrice} INR</div> */}
                                                     </label>) 
                                                 }
                                             })}                                            
                                         </Modal.Body>

                                         <Modal.Footer className={`${RegisterCss.marginSet}`}>
                                             <p  className={`${RegisterCss.secTwoRateText}`}>1 Subject = Rs. 300<br/>
                                             2 Subjects = Rs. 250 per subject<br/>
                                             3 Subjects = Rs. 200 per subject<br/>
                                             More than 3 Subjects = Rs. 175 per subject

                                             </p>
                                             <Button className={`${RegisterCss.okButton}`} variant="secondary" onClick={handleClose}>
                                                 Ok
                                             </Button>
                                         </Modal.Footer>
                                     
                                     </Modal>
                                    }
                                       

                                    <div >
                                        <label className={`${RegisterCss.lableHead}`}>Amount</label>
                                        <div className={`${RegisterCss.subjRow}`}>
                                            <div className={`${RegisterCss.rsSymbol}`}>
                                                <CurrencyRupeeIcon className={`${RegisterCss.rupIcon}`}/>
                                            </div>
                                            
                                            <div className={`${RegisterCss.inputBox}`}>
                                                <input 
                                                    className={`${RegisterCss.inputText}`} 
                                                    type="text" 
                                                    name='totalAmount'
                                                    id='totalAmount'
                                                    key='totalAmount'
                                                    value={promoCode.accessCode != "" ? (Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount)))-((Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount)))/100)*discount : Number(totalAmountAC)+Number(totalAmountLogic(totalDiscountedAmount))}
                                                    placeholder='amount'
                                                    autoComplete='off' 
                                                    readOnly 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={`${RegisterCss.btnRow}`}>
                                        <div className={`${RegisterCss.btnSetting}`}>
                                            <button type="submit" className={`${RegisterCss.logButton}`}>Pay & Register</button>                                           
                                        </div>
                                        <div className={`${RegisterCss.btnSetting}`}>
                                            <button type="button" className={`${RegisterCss.logButton}`} onClick={resetForm}>Reset</button>
                                        </div>
                                </div>
                            </form>
                        </> }
                        
                    </div>

                </div>
            </div>
            <div className={`${RegisterCss.area}`}>
                <ul className={`${RegisterCss.circles}`}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

            {showErrPopup.status && 
            <div className={`${RegisterCss.popupBack}`}>
              <div className={`${RegisterCss.errBox}`}>
                <CloseIcon className={`${RegisterCss.crossPopup}`} onClick={()=>{setErrPopup(false)}}/>
                <Alert severity="error">
                  {showErrPopup.msg}
                </Alert>
              </div>       
            </div>
        }


        </div>
        
    )
}